import React, { lazy, Suspense, StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const Interconsulta = lazy(() => import("./interconsulta/view"));

ReactDOM.render(
  <Router>
    <StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Interconsulta />} />
        </Routes>
      </Suspense>
    </StrictMode>
  </Router>,
  document.getElementById("root")
);
